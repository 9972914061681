import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { Spin } from "antd";
import Swal from "sweetalert2";
import { WhatsAppOutlined } from "@ant-design/icons";
import Modal from "../auth-phone/Modal";
import * as S from "../auth-phone/styles";
import AuthPhoneAPI from "../auth-phone/AuthPhoneAPI2";
import { saveState, selectRegisterstate } from "../RegisterPetSlice";
import { useAPITag } from "../../../services/api";

export const SelectAuthOption = () => {
  const { http } = useAPITag();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { ValidateTag } = AuthPhoneAPI();

  const registerState = useSelector(selectRegisterstate);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ValidateTag(id);
  }, []);

  const fetchGeneratedCodeAndRedirect = async () => {
    setIsLoading(true);
    try {
      const response = await http.post("/user-token/whatsapp", {
        tagCode: id,
        userId: "",
        phone: "",
      });

      dispatch(saveState());

      if (response.data.status) {
        const message = `Olá, vim ativar a coleira ${id} e o código gerado é: ${response.data.code}`;
        const whatsappUrl = `https://wa.me/15551947108?text=${encodeURIComponent(
          message
        )}`;
        window.location.href = whatsappUrl;
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Não foi possível gerar o código. Tente novamente mais tarde.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao gerar o código. Verifique sua conexão e tente novamente.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleWhatsAppRedirect = () => {
    setModalVisible(false);
    setIsLoading(true);
    fetchGeneratedCodeAndRedirect();
  };

  return !registerState.config.logo ? (
    <div className="flex items-center justify-center w-full h-screen">
      <Spin size="large" />
    </div>
  ) : (
    <RegisterPetLayout
      hasHeader
      description="Escolha a opção para ativar a coleira."
      title="Cadastrar nova coleira"
      size={5}
      step={1}
    >
      <div className="flex flex-col w-full gap-4 mt-4 justify-center items-center">
        <S.SubmitWhatsappButton
          onClick={() => {
            setModalVisible(true);
          }}
          type="primary"
          htmlType="button"
        >
          <WhatsAppOutlined /> Ativar coleira pelo WhatsApp
        </S.SubmitWhatsappButton>

        <Modal
          title="Confirmação de Redirecionamento"
          isOpen={isModalVisible}
          onClose={() => setModalVisible(false)}
          onConfirm={handleWhatsAppRedirect}
          confirmButtonProps={{ disabled: isLoading }}
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Spin size="large" />
            </div>
          ) : (
            <span style={{ fontSize: "16px" }}>
              Você será redirecionado para o WhatsApp para concluir a ativação.
              <br />
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Clique em confirmar para continuar.
              </span>
            </span>
          )}
        </Modal>
      </div>
    </RegisterPetLayout>
  );
};

export default SelectAuthOption;
