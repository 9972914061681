import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage, selectLanguage } from '../app/store/languageSlice';

const LanguageFloatingButton = styled.div`
  display: flex;
  position: fixed;
  bottom: 60px;
  right: 5px;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 90px;
  z-index: 50;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const LanguageChangeButton = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectLanguage);

  const languages = [
    {
      code: 'pt',
      label: 'Português',
      flag: 'https://flagsapi.com/BR/flat/64.png',
    },
    {
      code: 'en',
      label: 'English',
      flag: 'https://flagsapi.com/US/flat/64.png',
    },
    {
      code: 'es',
      label: 'Español',
      flag: 'https://flagsapi.com/ES/flat/64.png',
    },
  ];

  const handleLanguageChange = () => {
    const currentIndex = languages.findIndex(
      (lang) => lang.code === currentLanguage
    );
    const nextIndex = (currentIndex + 1) % languages.length;
    const nextLanguage = languages[nextIndex];

    dispatch(setLanguage(nextLanguage.code));
  };

  const currentLanguageData = languages.find(
    (lang) => lang.code === currentLanguage
  );

  return (
    <LanguageFloatingButton onClick={handleLanguageChange}>
      <FlagIcon
        src={currentLanguageData.flag}
        alt={`${currentLanguageData.label} Flag`}
      />
      {currentLanguageData.label}
    </LanguageFloatingButton>
  );
};

export default LanguageChangeButton;
