import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";

const normalizeLanguage = (lang) => (lang ? lang.split("-")[0] : null);

const savedLanguage = localStorage.getItem("language")
  ? normalizeLanguage(localStorage.getItem("language"))
  : normalizeLanguage(navigator.language) || "pt";

const languageSlice = createSlice({
  name: "language",
  initialState: savedLanguage,
  reducers: {
    setLanguage: (state, action) => {
      const language = action.payload;
      localStorage.setItem("language", language);
      i18n.changeLanguage(language);
      return language;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export const selectLanguage = (state) => state.language;

export default languageSlice.reducer;
