import React from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

const WhatsappFloatingButton = styled(Button)`
  display: flex;
  position: fixed;
  bottom: 15px;
  color: white !important;
  border-color: white !important;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px 24px;
  color: white;
  width: 90px;
  height: 40px;
  border-radius: 90px;
  z-index: 50;
  right: 5px;
  background: #128c7e !important;
  transition: bottom 0.3s ease;
`;

export const WhatsappButton = () => {
  return (
    <WhatsappFloatingButton
      onClick={() => {
        window.open(`https://wa.me/+5531994920047`);
      }}
    >
      Ajuda <WhatsAppOutlined />
    </WhatsappFloatingButton>
  );
};
