import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Spin } from "antd";
import ConfirmCodeAPI from "../confirm-code/ConfirmCodeAPI2";

export const FinishPage = () => {
  const { id } = useParams();
  const { CheckCode } = ConfirmCodeAPI();

  useEffect(() => {
    CheckCode({
      tagCode: id,
    });
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <Spin size="large" />
    </div>
  );
};
