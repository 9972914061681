import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const formatUserPhoneForDisplay = (phoneNumber) => {
  if (!phoneNumber || typeof phoneNumber !== 'string') {
    return '';
  }

  const parsed = parsePhoneNumberFromString(phoneNumber);
  if (!parsed) {
    return phoneNumber;
  }

  return parsed.formatInternational();
};
