import * as S from './styles';
import { DatePicker, Form, Switch, Col, Row, Typography } from 'antd';
import Swal from 'sweetalert2';
import { useCallback, useEffect, useState } from 'react';
import RegisterPetLayout from '../../../components/layouts/RegisterPetLayout';
import RegisterPetAPI from './RegisterPetAPI';

import {
  petFeatures,
  racasCachorro,
  racasGato,
  tiposSaude,
} from './RegisterPetMock';
import StyledDropzone from '../../../components/styledDropzone';
import { useSelector } from 'react-redux';
import { selectRegisterstate } from '../RegisterPetSlice';
import TextArea from 'antd/es/input/TextArea';
import { getUser } from '../../../app/store/authSlice';
import { NumericFormat } from 'react-number-format';
import { sortOptions } from '../../../data/utils/sortOptions';

export const RegisterPet = () => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const { createPet } = RegisterPetAPI();
  const [, updateState] = useState();
  const [photos, setPhotos] = useState([]);
  const [isCreating, creating] = useState(false);
  const registerState = useSelector(selectRegisterstate);
  const user = useSelector(getUser);
  const forceUpdate = useCallback(() => updateState({}), []);
  const [petSpecie, setPetSpecie] = useState('dog');

  const onFinish = async (values) => {
    creating(true);

    const { features, ...data } = values;

    data['fotos'] = photos;
    data['avatar'] = photos[0];
    data.dataNascimento = values.birthDate.toISOString();

    if (features) {
      features.forEach((e) => {
        data[e] = 5;
      });
    }

    data.tutor = user._id;
    data.tag = registerState.code;
    data.qrCodes = [registerState.code];

    await createPet(data);

    creating(false);
  };

  const onFinishFailed = () => {
    Swal.fire({
      title: 'Preencha todos os campos',
      icon: 'error',
    });

    creating(false);
  };

  useEffect(() => {
    form.setFieldValue('especie', 'dog');
  }, []);

  const onChange = (e) => {
    if (e.species && e.species !== petSpecie) {
      form.setFieldValue('breed', null);
      setPetSpecie(e.species);
    }
    forceUpdate();
  };

  return (
    <RegisterPetLayout
      hasHeader
      description="Insira as  informações do seu pet.
*campos obrigatórios
"
      title="Dados do seu Pet"
      size={5}
      step={4}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        onValuesChange={onChange}
        style={{ width: '80%' }}
        initialValues={{ remember: true, especie: 'dog' }}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
      >
        <StyledDropzone setValue={setPhotos} value={photos} />
        <Form.Item
          name="nome"
          label="Nome"
          rules={[{ required: true, message: 'Informe o nome do Pet' }]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          name="especie"
          label="Espécie"
          initialValue="dog"
          rules={[{ required: true, message: 'Informe a espécie do Pet' }]}
        >
          <S.StyledSelect
            initialValue="dog"
            options={[
              { value: 'dog', label: 'Cão' },
              { value: 'cat', label: 'Gato' },
            ]}
          />
        </Form.Item>

        {form.getFieldValue('especie') === 'dog' ||
        form.getFieldValue('especie') == undefined ? (
          <Form.Item
            name="raca"
            label="Raça"
            rules={[{ required: true, message: 'Informe a raça do Pet' }]}
          >
            <S.StyledSelect options={sortOptions(racasCachorro)} />
          </Form.Item>
        ) : (
          <Form.Item
            name="raca"
            label="Raça"
            rules={[{ required: true, message: ' Informe a raça do Pet' }]}
          >
            <S.StyledSelect options={sortOptions(racasGato)} />
          </Form.Item>
        )}

        <Form.Item
          name="genero"
          label="Sexo"
          rules={[{ required: true, message: 'Informe o genêro do Pet' }]}
        >
          <S.StyledSelect
            initialValue="female"
            options={[
              { value: 'fêmea', label: 'Fêmea' },
              { value: 'macho', label: 'Macho' },
            ]}
          />
        </Form.Item>

        <Form.Item
          initialValue="tudoCerto"
          name="saude"
          label="Estado de saúde"
        >
          <S.StyledSelect defaultValue="tudoCerto" options={tiposSaude} />
        </Form.Item>

        <Form.Item
          initialValue=""
          name="corPelo"
          label="Cor do Pêlo"
          rules={[{ required: true, message: 'Informe a cor do Pet' }]}
        >
          <S.StyledInput defaultValue="" />
        </Form.Item>

        <Col style={{ marginBottom: '10px' }} span={24}>
          <Row gutter={16}>
            <Col span={24}>
              <Text>Peso do Pet (KG)</Text>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={32}>
              <Text type="secondary">Ex: 20,300 = 20 Quilos e 300 gramas</Text>
            </Col>
          </Row>
        </Col>
        <Form.Item initialValue={0} name="peso">
          <NumericFormat
            className="w-full px-2 border h-[38px] rounded-[8px] hover:border hover:border-[#f67e48] transition ease-out delay-100 outline-none focus:border-[#f67e48]"
            maxLength={6}
            value="20020220"
            allowLeadingZeros
            thousandSeparator=","
          />
        </Form.Item>

        <Form.Item name="features" label="Características">
          <S.StyledSelect mode="multiple" allowClear options={petFeatures} />
        </Form.Item>
        <Form.Item
          name="birthDate"
          label="Data de nascimento"
          rules={[{ required: true, message: 'Informe a data de nascimento' }]}
        >
          <DatePicker
            format={'DD/MM/YYYY'}
            placeholder="Selecione a data de nascimento"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item initialValue={false} name="tail" label="Possui cauda?">
          <Switch />
        </Form.Item>
        <Form.Item
          defaultValue={false}
          name="adoption"
          label="Disponível para adoção?"
        >
          <Switch />
        </Form.Item>
        <Form.Item initialValue={false} name="chip" label="Possui chip?">
          <Switch />
        </Form.Item>
        {form.getFieldValue('chip') && (
          <Form.Item initialValue={''} name="chipN" label="Numéro do chip">
            <S.StyledInput />
          </Form.Item>
        )}
        <Form.Item initialValue={false} name="rescued" label="Foi resgatado?">
          <Switch />
        </Form.Item>

        {form.getFieldValue('rescued') && (
          <Form.Item
            defaultValue={''}
            name="rescueLocale"
            label="Local de regaste"
          >
            <S.StyledInput />
          </Form.Item>
        )}
        <Form.Item initialValue={''} name="notes" label="Observações">
          <TextArea rows={4} />
        </Form.Item>
        <S.SubmitButton disabled={isCreating} type="primary" htmlType="submit">
          Ativar coleira
        </S.SubmitButton>
      </Form>
    </RegisterPetLayout>
  );
};
