import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useAPITag } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { saveState, selectRegisterstate } from '../RegisterPetSlice';

const SelectPetAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const registerState = useSelector(selectRegisterstate);

  const selectPet = (id) => async () => {
    try {
      await http.patch(`/pets/active-tag/${id}/${registerState.code}`);
      dispatch(saveState());
      navigate('/success');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao selecionar o pet',
        text:
          error?.response?.data?.message ||
          'Ocorreu um erro inesperado. Por favor, tente novamente.',
        confirmButtonText: 'Confirmar',
      });
    }
  };

  return { selectPet };
};

export default SelectPetAPI;
