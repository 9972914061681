import IconHelp from '../../assets/help_icon.png';
import NewLogoHome from '../../assets/new-home-logo.png';
import ColeiraSemFundo from '../../assets/coleira-sem-fundo.png';
import CachorroCirculoAmarelo from '../../assets/cachorro-circulo-amarelo.png';
import SiteOverview from '../../assets/site-overview.png';
import QRCodeIcon from '../../assets/qr-code-icon.svg';
import LocationIcon from '../../assets/location_icon.png';
import DownloadIcon from '../../assets/download_icon.png';
import EditIcon from '../../assets/edit_icon.png';
import MoneyIcon from '../../assets/money_icon.png';
import { useDispatch } from 'react-redux';
import { openModal } from '../../app/store/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openModalAuth = () => {
    dispatch(openModal());
  };

  return (
    <div className="w-full bg-gray-100 flex flex-col items-center px-3">
      <div className="bg-white rounded-[33px] p-8 flex flex-col items-center justify-center w-full max-w-screen-md mt-[15px]">
        <div className="w-full flex justify-center mb-6">
          <img src={NewLogoHome} alt="Logo" className="w-[318px] h-auto" />
        </div>
        <h1
          className="text-gray-800 text-center font-semibold text-[39px] leading-tight mb-6 max-w-[600px]"
          style={{ wordWrap: 'break-word' }}
        >
          {t('homepage.header.title')}
        </h1>

        <button
          className="bg-[#F67E48] text-white w-full max-w-[275px] rounded-full h-[48px] font-medium"
          onClick={openModalAuth}
        >
          {t('homepage.header.loginButton')}
        </button>
        <p className="mt-4 text-[#353945] text-sm">
          {t('homepage.header.registerText')}{' '}
          <a href="/register-user" className="text-[#F67E48] font-medium">
            {t('homepage.header.registerLink')}
          </a>
        </p>
      </div>

      <div className="w-full max-w-screen-md flex flex-col gap-8 mt-8">
        <div className="bg-white rounded-[24px] p-8 flex flex-col gap-6 items-center">
          <div className="flex flex-col gap-6 items-center">
            <div className="order-1 md:order-2">
              <div className="text-[#ff6e30] font-extrabold uppercase tracking-wider text-sm text-center md:text-center mb-4">
                {t('homepage.card1.tagsLabel')}
              </div>
              <h1 className="text-3xl md:text-xl lg:text-3xl font-bold text-[#272E35] text-center md:text-left">
                {t('homepage.card1.title')}
              </h1>
              <p className="text-[#6B7280] text-base my-4 text-center md:text-left">
                {t('homepage.card1.description')}
              </p>

              <div className="flex justify-center mt-2 md:hidden">
                <img
                  src={ColeiraSemFundo}
                  alt={t('homepage.card1.imageAlt')}
                  className="w-[268px] h-auto mx-auto"
                />
              </div>

              <div className="hidden md:flex justify-center">
                <img
                  src={ColeiraSemFundo}
                  alt={t('homepage.card1.imageAlt')}
                  className="w-[268px] h-auto mx-auto"
                />
              </div>

              <ol className="text-[#6B7280] text-sm font-normal mt-6 list-none pl-0 space-y-2">
                <li className="flex items-start text-sm">
                  <span className="text-[#ff6e30] font-bold mr-2">
                    1&nbsp;-
                  </span>
                  {t('homepage.card1.steps.1')}
                </li>
                <li className="flex items-start text-sm">
                  <span className="text-[#ff6e30] font-bold mr-2">
                    2&nbsp;-
                  </span>
                  {t('homepage.card1.steps.2')}
                </li>
                <li className="flex items-start text-sm">
                  <span className="text-[#ff6e30] font-bold mr-2">
                    3&nbsp;-
                  </span>
                  {t('homepage.card1.steps.3')}
                </li>
                <li className="flex items-start text-sm">
                  <span className="text-[#ff6e30] font-bold mr-2">
                    4&nbsp;-
                  </span>
                  {t('homepage.card1.steps.4')}
                </li>
                <li className="flex items-start text-sm">
                  <span className="text-[#ff6e30] font-bold mr-2">
                    5&nbsp;-
                  </span>
                  {t('homepage.card1.steps.5')}
                </li>
              </ol>

              <p className="text-[#6B7280] text-sm mt-4 text-center md:text-left">
                {t('homepage.card1.conclusion')}
              </p>

              <div className="w-full flex justify-center items-center">
                <button
                  className="mt-4 bg-[#ff6e30] text-white text-sm rounded-full font-bold w-full max-w-[275px] h-[48px] px-6 py-3 uppercase flex items-center justify-center"
                  onClick={() => navigate('/leitor')}
                >
                  {t('homepage.card1.button')}
                  <img src={QRCodeIcon} alt="QR Code" className="ml-2" />
                </button>
              </div>
            </div>


          </div>
        </div>

        <div className="bg-white rounded-[24px] p-8 flex flex-col gap-6 items-center">
          <div className="flex flex-col gap-6 items-center">
            <div className="flex flex-col justify-center items-center md:items-center text-center md:text-center">
              <div className="text-[#ff6e30] font-extrabold uppercase tracking-wider text-sm text-center md:text-center mb-4">
                {t('homepage.card2.tagsLabel')}
              </div>
              <h1 className="text-3xl md:text-xl lg:text-3xl font-bold text-[#272E35]">
                {t('homepage.card2.title')}
              </h1>
              <p className="text-[#6B7280] text-base my-5">
                {t('homepage.card2.description')}
              </p>

              <div className="flex justify-center mt-6 md:hidden">
                <img
                  src={CachorroCirculoAmarelo}
                  alt={t('homepage.card2.imageAlt')}
                  className="w-[168px] h-auto"
                />
              </div>

              <div className="hidden md:flex justify-center w-full">
                <img
                  src={CachorroCirculoAmarelo}
                  alt={t('homepage.card2.imageAlt')}
                  className="w-[168px] h-auto mx-auto"
                />
              </div>

              <div className="w-full flex justify-center items-center mt-6">
                <button
                  className="bg-[#ff6e30] text-white text-sm rounded-full font-bold w-full max-w-[275px] h-[48px] px-6 py-3 uppercase flex items-center justify-center"
                  onClick={() => navigate('/leitor')}
                >
                  {t('homepage.card2.button')}
                  <img src={QRCodeIcon} alt="QR Code" className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-[24px] p-8 flex flex-col lg:gap-6 items-center mb-16">
          <img
            src={SiteOverview}
            alt="Gestor Pet"
            className="w-[336px] h-auto mx-auto"
          />
          <div>
            <h1 className="text-3xl md:text-xl lg:text-3xl font-bold text-[#23262f] text-center md:text-center my-4">
              {t('homepage.card3.title')}
            </h1>
            <p className="text-[#6B7280] text-[16px] font-normal mb-4 text-center md:text-left">
              {t('homepage.card3.description')}
            </p>
            <div className="mt-8 space-y-4 w-full">
              <div className="flex items-center">
                <img
                  src={MoneyIcon}
                  alt={t('homepage.card3.features.noFees')}
                  className="w-8 h-8 mr-4"
                />
                <p className="text-sm text-[#6B7280]">
                  {t('homepage.card3.features.noFees')}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={LocationIcon}
                  alt={t('homepage.card3.features.location')}
                  className="w-8 h-8 mr-4"
                />
                <p className="text-sm text-[#6B7280]">
                  {t('homepage.card3.features.location')}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={IconHelp}
                  alt={t('homepage.card3.features.returnPet')}
                  className="w-8 h-8 mr-4"
                />
                <p className="text-sm text-[#6B7280]">
                  {t('homepage.card3.features.returnPet')}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={EditIcon}
                  alt={t('homepage.card3.features.dataEdit')}
                  className="w-8 h-8 mr-4"
                />
                <p className="text-sm text-[#6B7280]">
                  {t('homepage.card3.features.dataEdit')}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={DownloadIcon}
                  alt={t('homepage.card3.features.noApp')}
                  className="w-8 h-8 mr-4"
                />
                <p className="text-sm text-[#6B7280]">
                  {t('homepage.card3.features.noApp')}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <a
                href={t('homepage.card3.buttonLink')}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-8 bg-[#ff6e30] text-white text-sm rounded-full font-bold w-full max-w-[275px] h-[48px] px-6 py-3 uppercase flex items-center justify-center"
              >
                {t('homepage.card3.button')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
