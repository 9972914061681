import React, { useEffect } from 'react';
import { Form } from 'antd';
import Avatar from '../../Avatar';
import Button from '../../Button';
import InputNew from '../../InputNew';
import * as S from '../styles';
import { useAPITag } from '../../../services/api';
import Swal from 'sweetalert2';

import {
  TwitterOutlined,
  InstagramOutlined,
  FacebookOutlined,
} from '@ant-design/icons';
import SocialMediaItem from '../../SocialMediaItem';
import { useForm } from 'antd/es/form/Form';
import { ConfigUserAPI } from '../ConfigUserAPI';
import { useSelector } from 'react-redux';
import { getUser } from '../../../app/store/authSlice';
import AvatarChanger from '../../AvatarChanger';
import {
  createDonee,
  getUserByEmail,
} from '../../../donate/services/donee-client';
import { createCustomLink } from '../../../donate/services/customLink-client';
import { useTranslation } from 'react-i18next';
import { PhoneNumberField } from '../../../features/register-pet/auth-phone/PhoneNumberField';
import { parsePhoneNumber } from 'react-phone-number-input';

const mockSocialMedias = [
  {
    icon: <TwitterOutlined style={{ color: 'gray' }} />,
    title: 'Twiter',
    user: '@michael',
    connected: true,
  },
  {
    icon: <InstagramOutlined style={{ color: 'gray' }} />,
    title: 'Instagram',
    user: '@mascarenhas',
    connected: false,
  },
  {
    icon: <FacebookOutlined style={{ color: 'gray' }} />,
    title: 'Facebook',
    user: 'facebook.com/michael',
    connected: true,
  },
];

const EditUser = () => {
  const { t } = useTranslation();
  const { getUserData } = ConfigUserAPI();
  const { http } = useAPITag();

  const user = useSelector(getUser);
  const [form] = useForm();

  const backPage = () => {
    window.history.back();
  };

  const updateUser = async (data) => {
    try {
      if (data.telefone) {
        const phoneNumber = parsePhoneNumber(data.telefone);
        if (!phoneNumber.isValid()) {
          throw new Error('Número de telefone inválido.');
        }
        data.countryCode = `+${phoneNumber.countryCallingCode}`;
        data.telefone = phoneNumber.nationalNumber;
      }
      await http.patch('user/' + user._id, data);
      await getUserData();
      Swal.fire({
        title: 'Dados atualizados com sucesso!',
        icon: 'success',
      });
      backPage();
    } catch (error) {
      Swal.fire({
        title: 'Erro ao atualizar os dados!',
        text: error.message || 'Tente novamente mais tarde.',
        icon: 'error',
      });
    }
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(
      `${window.location.host + '/doar/' + user.username}`
    );
  };

  const sendForm = () => {
    form.submit();
  };

  const onRegisterCustomLink = async () => {
    try {
      const userStorage = JSON.parse(localStorage.getItem('@customer'));

      if (userStorage.document) {
        if (!userStorage || !userStorage.document) {
          console.error('Os dados do usuário são indefinidos ou nulos');
          throw new Error('Os dados do usuário são indefinidos ou nulos');
        }
        let type = userStorage.document.length <= 12 ? 'user' : 'instituition';

        const { data } = await getUserByEmail(userStorage.email);

        if (!data) {
          console.error('Os dados do usuário são indefinidos ou nulos');
          throw new Error('Os dados do usuário são indefinidos ou nulos');
        }

        const bodyDonee = {
          type,
          entityId: data._id,
          customLink: data.username,
        };

        const doneeCreated = await createDonee(bodyDonee);

        if (!doneeCreated) {
          console.error('A criação do donatário falhou!');
          throw new Error('A criação do donatário falhou!');
        }

        const bodyCustomLink = {
          doneeId: doneeCreated._id,
          customLink: doneeCreated.customLink,
        };

        await createCustomLink(bodyCustomLink);
      }
    } catch (error) {
      console.error('Erro ao registrar link personalizado:', error);
      Swal.fire({
        title: 'Erro ao registrar link personalizado',
        icon: 'error',
      });
    }
  };

  const handleSave = async () => {
    try {
      await onRegisterCustomLink();
      sendForm();
    } catch (error) {
      console.error('Error in handleSave:', error);
      Swal.fire({
        title: 'Erro ao atualizar os dados! Tente novamente.',
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        username: user.username,
        customLink: `${window.location.host + '/doar/' + user.username}`,
        nome: user.name,
        sobrenome: user.lastName,
        telefone: `${user.countryCode}${user.phone}`,
        email: user.email,
      });
    }
  }, [user, form]);

  return (
    <>
      <Form
        onFinish={updateUser}
        form={form}
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
      >
        <S.WrapperTitle>
          {/* <S.Title className="-mt-8">Editar informações do perfil</S.Title> */}
        </S.WrapperTitle>

        {/* <S.WrapperSection>
          <Banner
            link={
              user.capa
                ? user.capa
                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6-_j20k-aa2lBk_M39b8Q18HeWsZNcOBdGTqxg3rHdw&s"
            }
            text="Editar capa"
          />
        </S.WrapperSection> */}

        <S.WrapperSection>
          {/* <S.Subtitle>Informações do perfil</S.Subtitle> */}
          <S.WrapperAvatar>
            <Avatar imageUrl={user.avatar} />
            <S.WrapperButtons position="left">
              <AvatarChanger text={t('editProfileImage')}></AvatarChanger>
            </S.WrapperButtons>
          </S.WrapperAvatar>
        </S.WrapperSection>
        <S.WrapperSection>
          <S.DivEmpty>
            <InputNew
              initialValue={user.username}
              name="username"
              label={t('username')}
            />

            <S.ContainerButton>
              <InputNew
                initialValue={`${
                  window.location.host + '/doar/' + user.username
                }`}
                name="customLink"
                disabled="true"
                label={t('customLink')}
              />
              <S.StyledCopyIcon onClick={copyToClipboard} />
            </S.ContainerButton>
          </S.DivEmpty>

          <S.WrapperInputs>
            <InputNew initialValue={user.name} name="nome" label={t('name')} />
            <InputNew
              initialValue={user.lastName}
              name="sobrenome"
              label={t('surname')}
            />
          </S.WrapperInputs>
          <S.WrapperInputs>
            <Form.Item
              name="telefone"
              label={t('phone')}
              initialValue={`${user.countryCode}${user.phone}`}
            >
              <PhoneNumberField />
            </Form.Item>
            <InputNew
              initialValue={user.email}
              name="email"
              type="email"
              label={t('email')}
            />
          </S.WrapperInputs>
        </S.WrapperSection>
        <S.WrapperSection>
          {/*           <S.Subtitle>Links Social Media</S.Subtitle>
           */}{' '}
          {false &&
            mockSocialMedias.map(({ title, icon, user, connected }, index) => {
              return (
                <SocialMediaItem
                  title={title}
                  icon={icon}
                  profile={user}
                  isConected={connected}
                  key={index}
                />
              );
            })}
        </S.WrapperSection>

        <S.WrapperSection>
          <S.WrapperButtons position="right">
            <Button minimal onClick={backPage} color="secundary">
              {t('cancel')}
            </Button>
            <Button minimal color="primary" onClick={handleSave}>
              {t('save')}
            </Button>
          </S.WrapperButtons>
        </S.WrapperSection>
      </Form>
    </>
  );
};

export default EditUser;
