import { Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import Swal from 'sweetalert2';
import './qr-scanner-modal-styles.css';

const QRScannerModal = ({ visible, onClose, onScanSuccess }) => {
  const qrCodeScannerRef = useRef(null);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [cameras, setCameras] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(false);

  useEffect(() => {
    const initializeScanner = async () => {
      try {
        const availableCameras = await Html5Qrcode.getCameras();
        setCameras(availableCameras);

        if (availableCameras.length > 0) {
          const bestCamera =
            availableCameras.find((camera) =>
              camera.label.toLowerCase().includes('back')
            ) || availableCameras[0];
          setSelectedCamera(bestCamera.id);
        }
      } catch (err) {
        Swal.fire('Erro', 'Não foi possível acessar a câmera.', 'error');
        onClose();
      }
    };

    initializeScanner();
  }, []);

  useEffect(() => {
    const startScanner = async () => {
      if (visible && selectedCamera) {
        const readerId = 'qr-reader-modal';

        if (!document.getElementById(readerId)) return;

        qrCodeScannerRef.current = new Html5Qrcode(readerId);

        const config = {
          fps: 10,
          qrbox: 250,
          aspectRatio: 1.0,
          disableFlip: false,
        };

        try {
          await qrCodeScannerRef.current.start(
            { deviceId: { exact: selectedCamera } },
            config,
            (decodedText) => {
              if (!isProcessing) {
                setIsProcessing(true);
                Swal.fire({
                  title: 'Processando...',
                  text: 'Aguarde enquanto processamos o QR Code.',
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });

                onScanSuccess(decodedText);

                setTimeout(() => {
                  setIsProcessing(false);
                  Swal.close();
                }, 2000);
              }
            },
            (error) => {
              if (error.name !== 'NotFoundException') {
                console.warn('Erro ao ler QR Code:', error);
              }
            }
          );
          setIsCameraActive(true);
        } catch (err) {
          console.error('Erro ao iniciar o scanner:', err);
          Swal.fire('Erro', 'Não foi possível iniciar o scanner.', 'error');
        }
      }
    };

    startScanner();

    return () => {
      if (qrCodeScannerRef.current) {
        qrCodeScannerRef.current
          .stop()
          .then(() => {
            const readerElement = document.getElementById('qr-reader-modal');
            if (readerElement) {
              qrCodeScannerRef.current.clear();
            }
            setIsCameraActive(false);
          })
          .catch((err) => {
            console.warn('Erro ao parar o scanner ou já estava parado:', err);
          });
      }
    };
  }, [visible, selectedCamera, isProcessing]);

  const handleClose = async () => {
    if (isCameraActive) {
      onClose();
    }
  };

  return (
    <Modal
      title="Ler QR Code"
      visible={visible}
      onCancel={() => {
        if (!isProcessing) handleClose();
      }}
      footer={null}
    >
      <p>Direcione sua câmera para o QRCODE de sua Coleira ou Plaquinha.</p>

      <div id="qr-reader-modal" className="qr-reader-frame"></div>

      <p className="camera-instructions">
        Caso sua câmera não esteja focando, tente selecionar outra abaixo.
      </p>

      <div className="camera-selection" style={{ marginTop: '16px' }}>
        <label htmlFor="camera-select" style={{ marginBottom: '8px' }}>
          Selecione a câmera:
        </label>
        <select
          id="camera-select"
          className="custom-select"
          value={selectedCamera}
          onChange={(e) => setSelectedCamera(e.target.value)}
        >
          {cameras.length > 0 ? (
            cameras.map((camera) => (
              <option key={camera.id} value={camera.id}>
                {camera.label || `Câmera ${camera.id}`}
              </option>
            ))
          ) : (
            <option>Nenhuma câmera disponível</option>
          )}
        </select>
      </div>
    </Modal>
  );
};

export default QRScannerModal;
